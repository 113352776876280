<template>
    <div class="reinvest">
        <h5header :name="$t('reward.desc35')" :right="false" />
        <div class="reinvest-top">
            <div class="top-con">
                <div class="top-btn flex">
                    <div class="flex" @click="handlerPrank" v-if="ismobile == 1">
                        <img src="../../public/images/reward/icon13.png" alt="" />

                    </div>
                    <div class="flex" @click="handlerRules">
                        <img src="../../public/images/reward/icon7.png" alt="" />
                        <div>{{ $t('reward.desc17') }}</div>
                    </div>
                    <div class="flex" @click="getRecord(1)">
                        <img src="../../public/images/reward/icon9.png" alt="" />
                        <div>{{ $t('reward.desc71') }}</div>
                    </div>
                    <div class="flex" @click="getprize(1)">
                        <img src="../../public/images/reward/icon10.png" alt="" />
                        <div>{{ $t('reward.desc72') }}</div>
                    </div>
                </div>
                <div class="top-title">{{ $t('reward.desc73') }}</div>
                <div class="top-name">{{ $t('reward.desc74', { n1: configInfo.activityParamOne * 100 }) }}</div>
                <div class="top-time flex">
                    <div>{{ $t('reward.desc75') }}</div> {{ common.formatDate(configInfo.startTime) }} - {{
                        common.formatDate(configInfo.endTime) }}(GST)
                </div>
                <div class="top-wrappar flex">
                    <div class="wrappar-info">
                        <div class="info-list">
                            <div class="info-record flexcenter" @click="getcarve(1)">{{ $t('reward.desc83') }}</div>
                            <div class="list-title flex">
                                <div class="title">{{ $t('reward.desc76') }}</div>
                                <p class="flex">{{ $t('reward.desc77') }}
                                    <van-count-down :time="weekInfo.selltime">
                                        <template #default="timeData">
                                            <span class="block" v-if="timeData.days < 10">0{{ timeData.days || 0 }}</span>
                                            <span class="block" v-else>{{ timeData.days || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc22') }}</span>
                                            <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours || 0 }}</span>
                                            <span class="block" v-else>{{ timeData.hours || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc23') }}</span>
                                            <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes ||
                                                0 }}</span>
                                            <span class="block" v-else>{{ timeData.minutes || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc24') }}</span>
                                            <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds ||
                                                0 }}</span>
                                            <span class="block" v-else>{{ timeData.seconds || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc25') }}</span>
                                        </template>
                                    </van-count-down>
                                </p>
                            </div>
                            <div class="list-award">
                                <div class="award-count flex">
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc78') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{ $t('reward.desc101', { n1: weekInfo.peopleNumber || 0 }) }}</p>
                                    </div>
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc79') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{ common.cutXiaoNum1(weekInfo.weekAmount) || 0 }} DU</p>
                                    </div>
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc80') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{ common.cutXiaoNum1(weekInfo.averageAmount) || 0 }} DU</p>
                                    </div>
                                </div>
                                <div class="award-time flex">{{ $t('reward.desc81') }} {{
                                    common.formatDate(weekInfo.grantTime || 0) }}(GST)
                                    <!-- <el-tooltip effect="dark"
                                        placement="bottom">
                                        <template #content>
                                            <div>{{ $t('reward.desc51') }}</div>
                                        </template>
                                        <img src="../../public/images/reward/icon12.png" alt="" />
                                    </el-tooltip> -->
                                </div>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="info-record flexcenter" @click="getcarve(2)">{{ $t('reward.desc84') }}</div>
                            <div class="list-title flex">
                                <div class="title">{{ $t('reward.desc82') }}</div>
                                <p class="flex">{{ $t('reward.desc77') }}
                                    <van-count-down :time="monInfo.selltime">
                                        <template #default="timeData">
                                            <span class="block" v-if="timeData.days < 10">0{{ timeData.days || 0 }}</span>
                                            <span class="block" v-else>{{ timeData.days || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc22') }}</span>
                                            <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours || 0 }}</span>
                                            <span class="block" v-else>{{ timeData.hours || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc23') }}</span>
                                            <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes ||
                                                0 }}</span>
                                            <span class="block" v-else>{{ timeData.minutes || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc24') }}</span>
                                            <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds ||
                                                0 }}</span>
                                            <span class="block" v-else>{{ timeData.seconds || 0 }}</span>
                                            <span class="colon">{{ $t('blind.desc25') }}</span>
                                        </template>
                                    </van-count-down>
                                </p>
                            </div>
                            <div class="list-award">
                                <div class="award-count flex">
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc78') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{ $t('reward.desc101', { n1: monInfo.peopleNumber || 0 }) }}</p>
                                    </div>
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc79') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{ common.cutXiaoNum1(monInfo.monthAmount) || 0 }} DU</p>
                                    </div>
                                    <div class="count">
                                        <div class="flex">
                                            {{ $t('reward.desc80') }}
                                            <!-- <el-tooltip effect="dark" placement="bottom">
                                                <template #content>
                                                    <div>{{ $t('reward.desc51') }}</div>
                                                </template>
                                                <img src="../../public/images/reward/icon12.png" alt="" />
                                            </el-tooltip> -->
                                        </div>
                                        <p>{{common.cutXiaoNum1( monInfo.averageAmount) || 0 }} DU</p>
                                    </div>
                                </div>
                                <div class="award-time flex">{{ $t('reward.desc81') }} {{
                                    common.formatDate(monInfo.grantTime || 0) }}(GST)
                                    <!-- <el-tooltip effect="dark"
                                        placement="bottom">
                                        <template #content>
                                            <div>{{ $t('reward.desc51') }}</div>
                                        </template>
                                        <img src="../../public/images/reward/icon12.png" alt="" />
                                    </el-tooltip> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrappar-rules">
                        <div class="title">{{ $t('reward.desc85') }}</div>
                        <div class="desc">{{ $t('reward.desc86', {
                            n1: configInfo.activityParamOne *
                                100, n2: configInfo.activityParamThree * 100, n3: configInfo.activityParamTwo * 100
                        }) }}</div>
                        <div class="rules-table">
                            <div class="table-title flex">
                                <div>{{ $t('reward.desc87') }}</div>
                                <div>{{ $t('reward.desc88') }}</div>
                                <div>{{ $t('reward.desc89') }}</div>
                            </div>
                            <div class="table-tbody flex">
                                <div>{{ $t('reward.desc90') }}</div>
                                <div>{{ $t('reward.desc91') }}</div>
                                <div>{{ $t('reward.desc92') }} <span>*{{ configInfo.activityParamOne * 100
                                }}%*{{ configInfo.activityParamThree * 100 }}%</span> </div>
                            </div>
                            <div class="table-tbody flex">
                                <div>{{ $t('reward.desc93') }}</div>
                                <div>{{ $t('reward.desc94') }}</div>
                                <div>{{ $t('reward.desc95') }}<span>*{{ configInfo.activityParamOne * 100
                                }}%*{{ configInfo.activityParamTwo * 100 }}%</span></div>
                            </div>
                        </div>
                        <div class="desc">{{ $t('reward.desc96') }}</div>
                        <div class="desc">{{ $t('reward.desc97') }}</div>
                        <div class="desc">{{ $t('reward.desc98') }}</div>
                        <div class="desc">{{ $t('reward.desc99') }}</div>
                        <div class="desc" id="act1">{{ $t('reward.desc100') }}</div>
                    </div>
                </div>
                <!-- <div class="top-market flexcenter" @click="$router.push('/planet')">{{ $t('market.desc37') }}</div> -->
            </div>
        </div>
        <div class="reinvest-con">
            <div class="reinvest-tab h5tab flex">
                <div class="flexcenter" :class="{ tabact: act == 1 }" @click="handlerTab(1)">{{ $t('reward.desc102') }}
                </div>
                <div class="flexcenter" :class="{ tabact: act == 2 }" @click="handlerTab(2)">{{ $t('reward.desc107') }}
                </div>
            </div>
            <div class="reinvest-title flex">
                <img src="../../public/images/new/inviteicon6.png" alt="">
                <div v-if="act == 1">{{ $t('reward.desc102') }}</div>
                <div v-else>{{ $t('reward.desc107') }}</div>
                <img src="../../public/images/new/inviteicon5.png" alt="">
            </div>
            <div class="reinvest-rules" v-if="act == 1">

                <div>{{ $t('reward.desc103') }} {{ common.formatDate(weekInfo.startWeekTime || 0) }} - {{
                    common.formatDate(weekInfo.endWeekTime || 0) }} (GST)</div>
                <div v-html="$t('reward.desc104')"></div>
                <div>{{ $t('reward.desc105') }}</div>
                <div
                    v-html="$t('reward.desc106', { n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamThree * 100 })">
                </div>
            </div>
            <div class="reinvest-rules" v-else>
                <div>{{ $t('reward.desc103') }} {{ common.formatDate(monInfo.startMonthTime || 0) }} - {{
                    common.formatDate(monInfo.endMonthTime || 0) }} (GST)</div>
                <div>{{ $t('reward.desc108') }}</div>
                <div>{{ $t('reward.desc105') }}</div>
                <div
                    v-html="$t('reward.desc109', { n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamTwo * 100 })">
                </div>
            </div>
            <div class="reinvest-tab flex">
                <div class="flexcenter" :class="{ tabact: act == 1 }" @click="handlerTab(1)">{{ $t('reward.desc102') }}
                </div>
                <div class="flexcenter" :class="{ tabact: act == 2 }" @click="handlerTab(2)">{{ $t('reward.desc107') }}
                </div>
            </div>
            <div class="reinvest-table" v-if="act == 1">
                <div class="table-thead flex">
                    <div>{{ $t('reward.desc45') }}</div>
                    <div>{{ $t('reward.desc110') }}</div>
                    <div>{{ $t('reward.desc111') }}</div>
                </div>
                <div class="table-tbody">
                    <div class="tbody-info flex" v-for="item in list" :key="item.id">
                        <div>
                            <img src="../../public/images/reward/parkicon1.png" alt="" v-if="item.park == 1" />
                            <img src="../../public/images/reward/parkicon2.png" alt="" v-else-if="item.park == 2" />
                            <img src="../../public/images/reward/parkicon3.png" alt="" v-else-if="item.park == 3" />
                            <span v-else>{{ item.park }}</span>
                        </div>
                        <div>{{ item.allAmount }} DU</div>
                        <div>{{ dealAddress(item.account) }}</div>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="" />
                    <span>{{ $t("record.desc13") }}</span>
                </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
            <div class="reinvest-table" v-else>
                <div class="table-thead flex">
                    <div>{{ $t('reward.desc45') }}</div>
                    <div>{{ $t('reward.desc116') }}</div>
                    <div>{{ $t('reward.desc111') }}</div>
                </div>
                <div class="table-tbody">
                    <div class="tbody-info flex" v-for="item in list" :key="item.id">
                        <div>
                            <img src="../../public/images/reward/parkicon1.png" alt="" v-if="item.park == 1" />
                            <img src="../../public/images/reward/parkicon2.png" alt="" v-else-if="item.park == 2" />
                            <img src="../../public/images/reward/parkicon3.png" alt="" v-else-if="item.park == 3" />
                            <span v-else>{{ item.park }}</span>
                        </div>
                        <div>{{ item.allAmount }} DU</div>
                        <div>{{ dealAddress(item.account) }}</div>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="" />
                    <span>{{ $t("record.desc13") }}</span>
                </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 瓜分记录 -->
        <van-popup v-model:show="showCarve">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showCarve = false" />
                <div class="modal-title">
                    <span v-if="carveId == 1">{{ $t('reward.desc112') }}</span>
                    <span v-else>{{ $t('reward.desc113') }}</span>
                </div>
                <div class="modal-table">
                    <div class="table-thead flex">
                        <div>{{ $t('reward.desc117') }}</div>
                        <div>{{ $t('reward.desc118') }}</div>
                        <div>{{ $t('reward.desc119') }}</div>
                        <div>{{ $t('reward.desc120') }}</div>
                        <div>{{ $t('reward.desc121') }}</div>
                        <div>{{ $t('reward.desc122') }}</div>
                        <div>{{ $t('reward.desc123') }}</div>
                    </div>
                    <div class="tbody">
                        <div class="tbody-info flex" v-for="item in carveList" :key="item.id">
                            <div> <span v-if="carveId == 1">{{ $t('reward.desc126') }}</span><span v-else>{{
                                $t('reward.desc127') }}</span> </div>
                            <div>{{ item.peopleNumber }}</div>
                            <div>
                                <span v-if="carveId == 1">{{ item.weekAmount }}</span><span v-else>{{ item.monthAmount
                                }}</span> DU
                            </div>
                            <div>{{ item.averageAmount }} DU</div>
                            <div>
                                <span v-if="carveId == 1">{{ common.formatDate1(item.startWeekTime) }} - {{
                                    common.formatDate1(item.endWeekTime) }}</span>
                                <span v-else>{{ common.formatDate1(item.startMonthTime) }} - {{
                                    common.formatDate1(item.endMonthTime) }}</span>
                            </div>
                            <div>{{ common.formatDate(item.grantTime) }}(GST)</div>
                            <div>
                                <span v-if="item.useState == 1">{{ $t('reward.desc124') }}</span>
                                <span v-else>{{ $t('reward.desc125') }}</span>
                            </div>
                        </div>
                        <div class="no-data" v-if="showNull1">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                    </div>
                    
                </div>
                <div class="modal-tips">
                    <span v-if="carveId == 1">{{ $t('reward.desc135') }} {{ $t('reward.desc114', {
                        n1: configInfo.activityParamOne * 100, n2:
                            configInfo.activityParamThree * 100
                    }) }}</span>
                    <span v-else>{{ $t('reward.desc135') }}{{ $t('reward.desc115', {
                        n1: configInfo.activityParamOne * 100, n2:
                            configInfo.activityParamTwo * 100
                    }) }}</span>
                </div>
            </div>
        </van-popup>
        <!-- 奖励记录 -->
        <van-popup v-model:show="showPrize">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showPrize = false" />
                <div class="modal-title">
                    {{ $t('reward.desc136') }}
                </div>
                <div class="modal-tab flex">
                    <div class="flexcenter" :class="{ priact: prizeId == 1 }" @click="getprize(1)">{{ $t('reward.desc137')
                    }}</div>
                    <div class="flexcenter" :class="{ priact: prizeId == 2 }" @click="getprize(2)">{{ $t('reward.desc138')
                    }}</div>

                </div>
                <div class="modal-table">
                    <div class="table-thead1 flex">
                        <div>{{ $t('reward.desc128') }}</div>
                        <div>{{ $t('reward.desc129') }}</div>
                        <div>{{ $t('reward.desc121') }}</div>
                        <div>{{ $t('reward.desc130') }}</div>
                        <div>{{ $t('reward.desc123') }}</div>
                        <div>{{ $t('reward.desc131') }}</div>
                    </div>
                    <div class="tbody" v-loading="loading">
                        <div class="tbody-info1 flex" v-for="item in carveList" :key="item.id">
                            <div> <span v-if="prizeId == 1">{{ $t('reward.desc137') }}</span><span v-else>{{
                                $t('reward.desc138') }}</span> </div>
                            <div>{{ item.allAmount }} DU</div>
                            <div>{{ common.formatDate1(item.startTime) }} - {{ common.formatDate1(item.endTime) }} 
                            </div>
                            <div>{{ common.formatDate(item.grantTime) }}(GST)</div>
                            <div> <span v-if="item.useState == 1">{{ $t('reward.desc124') }}</span>
                                <span v-else>{{ $t('reward.desc125') }}</span>
                            </div>
                            <div
                                @click="$router.push(`/record/detail?id=${item.awardCoinId}&act=${item.awardAccountType}`)">
                                {{ $t('reward.desc132') }} </div>
                        </div>
                        <div class="no-data" v-if="showNull1">
                            <img src="../../public/images/no-data.png" alt="" />
                            <span>{{ $t("record.desc13") }}</span>
                        </div>
                    </div>

                </div>
                <div class="modal-tips" v-if="prizeId == 1">
                    <div>{{ $t('reward.desc135') }}</div>
                    <div>1.{{ $t('reward.desc114', {
                        n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamThree
                            * 100
                    }) }}</div>
                    <div>{{ $t('reward.desc133') }}</div>


                </div>
                <div class="modal-tips" v-if="prizeId == 2">
                    <div>{{ $t('reward.desc135') }}</div>
                    <div>1.{{ $t('reward.desc115', {
                        n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamTwo *
                            100
                    }) }}</div>
                    <div>{{ $t('reward.desc134') }}</div>
                </div>
            </div>
        </van-popup>
        <!-- 复投记录 -->
        <van-popup v-model:show="showRecord">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showRecord = false" />
                <div class="modal-title">
                    {{ $t('reward.desc139') }}
                </div>
                <div class="modal-tab flex">
                    <div class="flexcenter" :class="{ priact: prizeId == 1 }" @click="getRecord(1)">{{ $t('reward.desc145')
                    }}</div>
                    <div class="flexcenter" :class="{ priact: prizeId == 2 }" @click="getRecord(2)">{{ $t('reward.desc146')
                    }}</div>

                </div>
                <div class="modal-table">
                    <div class="table-thead2 flex">
                        <div>{{ $t('reward.desc117') }}</div>
                        <div>{{ $t('reward.desc140') }}</div>
                        <div>{{ $t('reward.desc141') }}</div>
                        <div v-if="prizeId == 1">{{ $t('reward.desc142') }}</div>
                    </div>
                    <div class="tbody" v-loading="loading">
                        <div class="tbody-info2 flex" v-for="item in carveList" :key="item.id">
                            <div> <span v-if="prizeId == 1">{{ $t('reward.desc143') }}</span><span v-else>{{
                                $t('reward.desc144') }}</span> </div>
                            <div>{{ item.allAmount }} DU</div>
                            <div>{{ common.formatDate1(item.starttime) }} -{{ common.formatDate1(item.endtime) }} (GST)</div>
                            <div v-if="prizeId == 1">{{ item.activityRanking }}</div>
                        </div>
                        <div class="no-data" v-if="showNull1">
                            <img src="../../public/images/no-data.png" alt="" />
                            <span>{{ $t("record.desc13") }}</span>
                        </div>
                    </div>

                </div>
                <div class="modal-tips">
                    <span v-if="prizeId == 1">{{ $t('reward.desc135') }} {{ $t('reward.desc114', {
                        n1: configInfo.activityParamOne * 100, n2:
                            configInfo.activityParamThree * 100
                    }) }}</span>
                    <span v-else>{{ $t('reward.desc135') }}{{ $t('reward.desc115', {
                        n1: configInfo.activityParamOne * 100, n2:
                            configInfo.activityParamTwo * 100
                    }) }}</span>
                </div>

            </div>
        </van-popup>
        <!-- 活动规则 -->
        <van-popup v-model:show="showNotice" :close-on-click-overlay="false">
            <div class="notice-con">
                <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="showNotice = false" />
                <div class="notice-name">{{ $t("reward.desc17") }}：</div>

                <div class="rules-list">
                    <div
                        v-html="$t('reward.desc147', { n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamThree * 100, n3: configInfo.activityParamTwo * 100 })">
                    </div>
                    <div>{{ $t('reward.desc148') }}<label>{{ common.formatDate(configInfo.startTime) }} - {{
                        common.formatDate(configInfo.endTime) }}(GST)</label> </div>
                    <div>{{ $t('reward.desc149') }}</div>
                    <div>{{ $t('reward.desc150') }}</div>
                    <div>{{ $t('reward.desc151') }}</div>
                    <div class="rules-table">
                        <div class="table-title flex">
                            <div>{{ $t('reward.desc152') }}</div>
                            <div>{{ $t('reward.desc141') }}</div>
                            <div>{{ $t('reward.desc159') }}</div>
                        </div>
                        <div class="tablle-info flex" v-for="item in issueList" :key="item.num">
                            <div>{{ $t('reward.desc153', { n1: item.num }) }}</div>
                            <div>{{ item.starttime }} {{ $t('reward.desc160') }} {{ item.endtime }} (GST)</div>
                            <div>{{ item.sendtime }}</div>
                        </div>
                    </div>
                    <div>{{ $t('reward.desc154') }}</div>
                    <div class="montitle flex">
                        <div>{{ $t('reward.desc141') }}</div>
                        <div>{{ $t('reward.desc159') }}</div>
                    </div>
                    <div class="montime flex">
                        <div>2023-11-24 11:00:00-2024-01-21 23:59:59 (GST)</div>
                        <div>2024-01-22 02:00</div>
                    </div>
                    <div>{{ $t('reward.desc155') }}<label>{{ configInfo.awardCoinName }}</label></div>
                    <div>{{ $t('reward.desc156') }}{{ configInfo.awardAccountTypeName }}</div>
                    <div>{{ $t('reward.desc157') }}</div>
                    <div>{{ $t('reward.desc158') }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            showNotice: false,
            showRecord: false,
            prizeId: 1,
            showPrize: false,
            showCarve: false,
            carveId: 1,
            weekTime: 0,
            monTime: 0,
            act: 1,
            page: 1,
            list: [],
            totalInfo: [],
            showNull: false,
            totals: 0,
            startWeekTime: 0,
            startMonTime: 0,
            configInfo: {},
            weekTime1: '',
            monTime1: '',
            carveList: [],
            showNull1: false,
            issueList: [
                {
                    num: 1,
                    starttime: '2023-12-23 11:00:00',
                    endtime: '2023-12-24 23:59:59',
                    sendtime: '2023-12-25 02:00:00'
                },
                {
                    num: 2,
                    starttime: '2023-12-25 00:00:00',
                    endtime: '2024-12-31 23:59:59',
                    sendtime: '2024-01-01 02:00:00'
                },
                {
                    num: 3,
                    starttime: '2024-01-01 00:00:00',
                    endtime: '2024-01-07 23:59:59',
                    sendtime: '2024-01-08 02:00:00'
                },
                {
                    num: 4,
                    starttime: '2024-01-08 00:00:00',
                    endtime: '2024-01-14 23:59:59',
                    sendtime: '2024-01-15 02:00:00'
                },
                {
                    num: 5,
                    starttime: '2024-01-15 00:00:00',
                    endtime: '2024-01-21 23:59:59',
                    sendtime: '2024-01-22 02:00:00'
                }
            ],
            weekInfo: {},
            monInfo: {},
            ismobile: 0,
            loading: false
        }
    },
    mounted() {
        this.$route.query.act == undefined ? this.act = 1 : this.act = this.$route.query.act;
        this.ismobile = localStorage.getItem('ismobile');
        // this.$post(this.URL.reward.initreinvest, {}).then(res => {
        //     if (res.code == 0) {
        this.init()
        //     }
        // })


    },
    methods: {
        handlerPrank() {

            let Id = `act1`;
            const section = document.getElementById(Id);
            console.log(section, Id)
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        gettime() {
            return new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000);
        },
        init() {
            let weekday = new Date(`${this.getThisSunday()} 23:59:59`), weekdaytime = weekday.getTime(), todayTime = new Date().getTime(), monday = new Date(this.daysRemainingInThisMonth()).getTime();
            this.weekTime = weekdaytime - todayTime;
            this.startWeekTime = this.common.formatDate(weekdaytime);
            this.startMonTime = this.common.formatDate(monday)
            this.monTime = monday - todayTime;
            this.$post(this.URL.reward.reinvestTotal, {}).then(res => {
                if (res.code == 0 && res.data != '') {

                    this.weekInfo = res.data[0];

                    let time1 = 4 * 60 * 60 * 1000, time2 = this.gettime();
                    this.weekInfo.selltime = this.weekInfo.grantTime - time2;
                    this.weekInfo.grantTime = this.weekInfo.grantTime - time1;
                    this.weekInfo.startWeekTime = this.weekInfo.startWeekTime - time1;
                    this.weekInfo.endWeekTime = this.weekInfo.endWeekTime - time1;
                    if (res.data[1] != undefined) {
                        this.monInfo = res.data[1];
                        this.monInfo.selltime = this.monInfo.grantTime - time2;
                        this.monInfo.grantTime = this.monInfo.grantTime - time1;
                        this.monInfo.endMonthTime = this.monInfo.endMonthTime - time1;
                        this.monInfo.startMonthTime = this.monInfo.startMonthTime - time1;
                    }
                }
            })
            this.$post(this.URL.reward.land, {
                activityType: 2
            }).then(res => {
                if (res.code == 0) {
                    if (res.data.awardAccountType == 1) {
                        res.data.awardAccountTypeName = this.$t('new.desc7')
                    } else if (res.data.awardAccountType == 2) {
                        res.data.awardAccountTypeName = this.$t('new.desc8')
                    } else {
                        res.data.awardAccountTypeName = this.$t('new.desc9')
                    }
                    let time1 = 4 * 60 * 60 * 1000;
                    res.data.startTime = res.data.startTime - time1;
                    res.data.endTime = res.data.endTime - time1;
                    this.configInfo = res.data;
                }
            })
            // 判断统计时间

            // if (todayTime > localStorage.getItem('startTime') && localStorage.getItem('startTime') != null) {
            //     this.weekTime1 = `${this.getWeek(new Date(), 0, 0)} 00:00:00 - ${this.getThisSunday()} 23:59:59`;

            // } else {
            //     this.weekTime1 = `${this.common.formatDate1(todayTime)} 00:00:00 - ${this.getThisSunday()} 23:59:59`;
            //     localStorage.setItem('startTime', weekdaytime)

            // }
            // if (todayTime > localStorage.getItem('startMontime') && localStorage.getItem('startMontime') != null) {
            //     let currentDate = new Date(), currentMonth = currentDate.getMonth() + 1, monstart = `${currentDate.getFullYear()}-${currentMonth.toString().padStart(2, '0')}-01 00:00:00`
            //     this.monTime1 = `${monstart} - ${this.common.formatDate(monday)}`;
            // } else {
            //     this.monTime1 = `${this.common.formatDate1(todayTime)} 00:00:00 - ${this.common.formatDate(monday)}`;
            //     localStorage.setItem('startMontime', monday)
            // }
            //
            this.getlist();
        },
        handlerRules() {
            if (this.ismobile == 1) {
                this.$router.push('/reward/rules');
                return;
            }
            this.showNotice = true;
        },
        getWeek(n, type) { // 周
            let now = new Date()
            let day = now.getDay() //返回星期几的某一天;
            if (!type) {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1)
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7)
                } else {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber)
                }
            } else {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1 + 6) // 在周开始的日期上+6天=周结束
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7 + 6)
                } else {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber)
                }
            }
            let date = now.getDate()
            let month = now.getMonth() + 1

            let s = now.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
            return s
        },
        handlerTab(i) {
            this.act = i;
            this.page = 1;
            this.list = [];
            this.showNull = false;
            this.getlist();
        },
        handlerCurrent(val) {
            this.page = val;
            this.list = [];
            this.showNull = false;
            this.getlist();
        },
        dealAddress(str) {
            if (str && str.length > 3) {
                return (
                    str.substring(0, 2) +
                    "****" +
                    str.substring(str.length - 2, str.length)
                );
            } else {
                return str.substring(0, 2) +
                    "****" +
                    str.substring(str.length - 1, str.length);
            }
        },
        getThisSunday() {
            var date = new Date();

            let w = date.getDay();//获取一下今天是周几
            let delta = 7 - w;//算算差几天到周日
            date.setDate(date.getDate() + delta);
            date = date.toJSON()
            date = date.substring(0, 10)
            return date;
        },
        daysRemainingInThisMonth() {
            let now = new Date(), monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
            return monthEnd;
        },
        getlist() {
            this.$post(this.URL.reward.reinvestRecord, {
                type: this.act,
                page: this.page,
                pageSize: 10
            }).then(res => {
                if (res.code == 0) {
                    if (res.data == null) {
                        this.showNull = true;
                        return;
                    }
                    this.list = res.data.records;
                    if (this.list.length != 0) {
                        this.list.forEach((e, i) => {
                            let page = this.page - 1;
                            this.page == 1 ? e.park = (i + 1) : e.park = (page * 10) + (i + 1)
                        });
                    }
                    this.totals = res.data.total;
                    if (this.totals == 0) {
                        this.showNull = true;
                    }
                } else {
                    this.$message(res.message)
                }
            })
        },
        //瓜分记录
        getcarve(i) {
            if (this.ismobile == 1) {
                this.$router.push(`/reward/carve?act=${i}`)
                return;
            }
            this.carveId = i;
            this.showNull1 = false;
            this.carveList = [];
            this.$post(this.URL.reward.carve, {
                type: this.carveId
            }).then(res => {
                if (res.code == 0) {
                    //this.carveList = res.data;
                    this.showCarve = true;
                    if(res.data==''){
                        this.showNull1 = true;
                        return;
                    }
                    res.data.forEach( e =>{
                        if(e.useState==1){
                            this.carveList.push(e)
                        }
                    })
                    if(this.carveList.length>0){
                        this.carveList.forEach( e =>{
                            let time1 = 4 * 60 * 60 * 1000;
                            e.grantTime=e.grantTime-time1;
                            e.startWeekTime=e.startWeekTime -time1;
                            e.endWeekTime=e.endWeekTime -time1;
                            e.startMonthTime=e.startMonthTime -time1;
                            e.endMonthTime=e.endMonthTime -time1;
                        })
                    }
                    if (this.carveList.length == 0) {
                        this.showNull1 = true;
                    }
                  
                } else {
                    this.$message(res.message)
                }
            })
        },
        //奖励记录
        getprize(i) {
            if (this.ismobile == 1) {
                this.$router.push('/reward/prize')
                return;
            }
            this.prizeId = i;
            this.showNull1 = false;
            this.carveList = [];
            this.loading = true;
            this.$post(this.URL.reward.prize, {
                type: this.prizeId
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    if (!this.showPrize) {
                        this.showPrize = true;
                    }
                    if (res.data == null) {
                        this.showNull1 = true;
                        return;
                    }
        
                    res.data.forEach( e =>{
                        if(e.useState==1){
                            this.carveList.push(e)
                        }
                    })
                    if(this.carveList.length>0){
                        this.carveList.forEach( e =>{
                            let time1 = 4 * 60 * 60 * 1000;
                            e.grantTime=e.grantTime-time1;
                            e.startTime=e.startTime -time1;
                            e.endTime=e.endTime -time1;
                        })
                    }
                } else {
                    this.$message(res.message)
                }
            })
        },
        //复投记录
        getRecord(i) {
            if (this.ismobile == 1) {
                this.$router.push('/reward/record')
                return;
            }
            this.showNull1 = false;
            this.loading = true;
            this.prizeId = i;

            this.carveList = [];
            this.$post(this.URL.reward.record, {
                type: this.prizeId
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    if (!this.showRecord) {
                        this.showRecord = true;
                    }
                    if (res.data == '') {
                        this.showNull1 = true;
                        return;
                    }
                 
                    this.carveList = res.data;
                    this.carveList.forEach( e =>{
                            let time1 = 4 * 60 * 60 * 1000;
                           if(this.prizeId==1){
                            e.endtime=e.weekEndTime-time1;
                            e.starttime=e.weekStartTime-time1;
                           }else{
                            e.endtime=e.monthEndTime-time1;
                            e.starttime=e.monthStartTime-time1;
                           }
                        })
                } else {
                    this.$message(res.message)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.reinvest {
    padding: 96px 0 80px;

    .reinvest-top {
        background: url('../../public/images/reward/reinvestbg.png') top no-repeat;
        background-size: 100% 1028px;

        .top-con {
            width: 1240px;
            padding: 22px 0 0;
            margin: 0 auto;

            .top-btn {
                justify-content: flex-end;

                .flex {
                    padding: 0 20px;
                    justify-content: center;
                    height: 36px;
                    margin-left: 16px;
                    border-radius: 90px;
                    background: #181818;
                    font-size: 13px;
                    color: #FFFFFF;
                    line-height: 36px;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                        margin: 8px 4px 0 0;
                    }
                }
            }

            .top-title {
                padding: 73px 0 40px;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 24px;
            }

            .top-name {
                font-size: 56px;
                color: #FFC120;
                font-weight: 600;
                line-height: 66px;
            }

            .top-time {
                margin-top: 24px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                line-height: 16px;
            }

            .top-wrappar {
                margin-top: 60px;
                justify-content: space-between;

                .wrappar-info {
                    flex: 0 0 746px;

                    .info-list {
                        position: relative;
                        margin-bottom: 40px;

                        .info-record {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 149px;
                            height: 48px;
                            background: url('../../public/images/reward/btnbg8.png') center no-repeat;
                            background-size: 100% 100%;
                            cursor: pointer;
                            font-size: 16px;
                            color: #040603;
                            text-indent: 20px;
                            text-align: center;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .list-title {
                            margin-bottom: 16px;
                            justify-content: space-between;

                            .title {
                                font-size: 20px;
                                color: #C9FA5B;
                                font-weight: 600;
                                line-height: 20px;
                            }

                            p {
                                font-size: 16px;
                                color: rgba(255, 255, 255, 0.80);
                                line-height: 16px;

                                .block {
                                    font-size: 16px;
                                    color: #C9FA5B;
                                    line-height: 16px;
                                }

                                .colon {
                                    padding: 0 4px;
                                    font-size: 16px;
                                    color: rgba(255, 255, 255, 0.80);
                                    line-height: 16px;
                                }
                            }
                        }

                        .list-award {
                            background: url('../../public/images/reward/bg3.png') top no-repeat;
                            background-size: 100% 100%;

                            .award-count {
                                padding: 40px 30px 0;
                                height: 160px;

                                .count {
                                    flex: 0 0 33.3%;

                                    div {
                                        position: relative;
                                        font-size: 20px;
                                        color: #FFFFFF;
                                        line-height: 20px;
                                        text-indent: 16px;

                                        img {
                                            width: 16px;
                                            height: 16px;
                                            margin: 2px 0 0 4px;
                                            cursor: pointer;
                                        }

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            top: 3px;
                                            left: 0;
                                            width: 14px;
                                            height: 14px;
                                            background: url('../../public/images/reward/icon11.png') center no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    p {
                                        margin-top: 24px;
                                        font-size: 30px;
                                        color: #C9FA5B;
                                        line-height: 44px;
                                    }
                                }
                            }

                            .award-time {
                                width: 615px;
                                padding: 27px 0 24px 29px;
                                font-size: 16px;
                                color: rgba(255, 255, 255, 0.8);
                                line-height: 16px;
                                border-top: 1px solid rgba(255, 255, 255, 0.2);

                                img {
                                    width: 14px;
                                    height: 14px;
                                    cursor: pointer;
                                    margin: 1px 0 0 4px;
                                }
                            }
                        }
                    }
                }

                .wrappar-rules {
                    flex: 0 0 440px;
                    padding: 32px;
                    height: 568px;
                    background: url('../../public/images/reward/bg2.png') top no-repeat;
                    background-size: 100% 100%;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 4px;
                        height: 4px;
                        background-color: rgba(255, 255, 255, .4);
                    }

                    /*定义滚动条轨道
 内阴影+圆角*/
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    /*定义滑块
     内阴影+圆角*/
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                        background-color: rgba(0, 0, 0, .5);
                    }

                    .title {
                        margin-bottom: 20px;
                        font-size: 20px;
                        color: #C9FA5B;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    .desc {
                        font-size: 14px;
                        color: #FFFFFF;

                    }

                    .rules-table {
                        margin: 16px 0 12px;
                        padding: 20px;
                        border-radius: 8px;
                        background: rgba(24, 24, 24, 0.90);

                        .table-title {
                            padding-bottom: 16px;
                            border-bottom: 1px solid rgba(255, 255, 255, 0.06);

                            div {
                                flex: 1;
                                font-size: 14px;
                                color: rgba(255, 255, 255, 0.6);
                                line-height: 14px;

                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }

                        .table-tbody {
                            padding: 21px 0 16px;
                            border-top: 1px solid rgba(255, 255, 255, 0.06);

                            &:last-child {
                                padding: 16px 0 0;
                            }

                            div {
                                flex: 1;
                                font-size: 13px;
                                color: #fff;
                                line-height: 14px;

                                &:last-child {
                                    text-align: right;
                                }

                                span {
                                    color: #C9FA5B;
                                }
                            }
                        }
                    }
                }
            }

            .top-market {
                margin-top: 60px;
                width: 208px;
                height: 52px;
                background: url('../../public/images/reward/btnbg1.png') center no-repeat;
                background-size: 100% 100%;
                font-size: 16px;
                color: #000000;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .reinvest-con {
        width: 1240px;
        margin: 120px auto 0;

        .reinvest-title {
            justify-content: center;

            img {
                flex: 0 0 95px;
                width: 95px;
                height: 17px;
                margin-top: 11px;
            }

            div {
                padding: 0 16px;
                font-size: 40px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 40px;
            }
        }

        .reinvest-rules {
            margin: 50px 0 40px;
            padding: 30px;
            border: 1px solid rgba(255, 255, 255, 0.20);

            div {
                margin-bottom: 12px;
                font-size: 16px;
                color: #FFFFFF;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .h5tab {
            display: none;
        }

        .reinvest-tab {
            margin-bottom: 24px;

            div {
                flex: 0 0 146px;
                width: 146px;
                height: 36px;
                margin-right: 19px;
                background: url('../../public/images/reward/btnbg6.svg') center no-repeat;
                background-size: cover;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: #000000;
                    background: url('../../public/images/reward/btnbg7.svg') center no-repeat;
                    background-size: cover;
                }
            }

            .tabact {
                color: #000000;
                background: url('../../public/images/reward/btnbg7.svg') center no-repeat;
                background-size: cover;
            }
        }

        .table-thead {
            padding: 20px 24px;
            background: #181818;

            div {
                flex: 1;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }

        .tbody-info {
            padding: 20px 24px;
            border-bottom: 1px solid #1A1A1A;

            div {
                flex: 1;
                font-size: 14px;
                color: #fff;
                line-height: 22px;

                img {
                    width: 22px;
                    height: 22px;
                }

                &:nth-child(2) {
                    color: #C9FA5B;
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.planet-page {
    margin-top: 40px;
    padding: 0;

    ::v-deep .el-pagination {
        button {
            background-color: rgba(255, 255, 255, 0);
            color: #666666;
            font-size: 14px;
        }

        .el-pager {
            li {
                background: rgba(255, 255, 255, 0);
                font-size: 14px;
                color: #666666;
            }

            .is-active {
                background: #c9fa5b !important;
                border-radius: 4px !important;
                color: #000000 !important;
            }
        }
    }
}

.no-data {
    width: 100%;
    padding: 40px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}



.modal-con {
    position: relative;
    width: 1000px;
    padding: 40px;

    .close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        font-size: 20px;
        color: #FFFFFF;
        line-height: 20px;
    }

    .modal-table {
        margin-top: 22px;

        .table-thead {
            padding: 20px;
            background: #232323;

            div {
                flex: 1;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.6);

                &:last-child {
                    flex: 0 0 80px;
                    text-align: right;
                }

                &:first-child {
                    flex: 0 0 85px;
                }

                &:nth-child(2) {
                    flex: 0 0 90px;
                }

                &:nth-child(3) {
                    flex: 0 0 160px;
                }

                &:nth-child(4) {
                    flex: 0 0 120px;
                }
            }
        }

        .tbody {
            min-height: 236px;

            ::v-deep .el-loading-mask {
                background-color: rgba(255, 255, 255, 0);

                .el-loading-text {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }

        .tbody-info {
            padding: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);

            div {
                flex: 1;
                font-size: 13px;
                color: #FFFFFF;

                &:last-child {
                    flex: 0 0 80px;
                    text-align: right;
                }

                &:first-child {
                    flex: 0 0 85px;
                }

                &:nth-child(2) {
                    flex: 0 0 90px;
                }

                &:nth-child(3) {
                    flex: 0 0 160px;
                }

                &:nth-child(4) {
                    flex: 0 0 120px;
                }
            }
        }
    }

    .modal-tips {
        margin-top: 22px;
        font-size: 13px;
        color: #E15757;

        div {
            margin-top: 6px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    //奖励
    .modal-tab {
        margin-top: 22px;

        div {
            flex: 0 0 76px;
            height: 36px;
            margin-right: 16px;
            background: url('../../public/images/reward/btnbg9.png') center no-repeat;
            background-size: 100% 100%;
            text-align: center;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;

            &:hover {
                background: url('../../public/images/reward/btnbg10.png') center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }

        .priact {
            background: url('../../public/images/reward/btnbg10.png') center no-repeat;
            background-size: 100% 100%;
            color: #000000;
        }
    }

    .table-thead1 {
        padding: 20px;
        background: #232323;

        div {
            flex: 1;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);

            &:last-child {
                flex: 0 0 80px;
                text-align: right;
            }

            &:first-child {
                flex: 0 0 100px;
            }

            &:nth-child(2) {
                flex: 0 0 140px;
            }

            &:nth-child(5) {
                flex: 0 0 100px;
            }
        }
    }

    .tbody-info1 {
        padding: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        div {
            flex: 1;
            font-size: 13px;
            color: #fff;

            &:last-child {
                flex: 0 0 80px;
                text-align: right;
                color: #C9FA5B;
                cursor: pointer;
            }

            &:first-child {
                flex: 0 0 100px;
            }

            &:nth-child(2) {
                flex: 0 0 140px;
            }

            &:nth-child(5) {
                flex: 0 0 100px;
            }
        }
    }

    //复投记录
    .table-thead2 {
        padding: 20px;
        background: #232323;

        div {
            flex: 1;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);

            &:last-child {
                text-align: right;
            }
        }
    }

    .tbody-info2 {
        padding: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        div {
            flex: 1;
            font-size: 13px;
            color: #fff;

            &:last-child {
                text-align: right;
            }
        }
    }
}

::v-deep .van-popup {
    border-radius: 20px;
    overflow-y: initial;
}

.notice-con {
    position: relative;
    width: 684px;
    padding: 40px 0;
    max-height: 653px;
    background: url("../../public/images/reward/rulesbg.png") top no-repeat;
    background-size: 100% 653px;

    &::after {
        content: '';
        position: absolute;
        top: -30px;
        right: 45px;
        width: 154px;
        height: 158px;
        background: url("../../public/images/reward/rules.png") top no-repeat;
        background-size: 100% 100%;
    }

    .close {
        position: absolute;
        bottom: -58px;
        left: 50%;
        width: 38px;
        height: 38px;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .notice-name {
        padding: 20px 40px 0;
        margin-bottom: 32px;
        font-size: 24px;
        color: #c9fa5b;
        line-height: 24px;
    }

    .notice-title {
        padding: 0 40px 12px;
        font-size: 18px;
        color: #ffffff;
    }


    .rules-list {
        max-height: 430px;
        overflow-y: scroll;
        padding: 0 40px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);

        div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                color: #C9FA5B;
            }
        }

        &::-webkit-scrollbar {
            width: 0px; //修改滚动条宽度
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #666666;
        }

        .rules-table {

            .table-title {
                padding: 20px;
                background: #232323;
                margin-bottom: 0;

                div {
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 0;

                    &:nth-child(2) {
                        flex: 1;
                    }

                    &:last-child {
                        flex: 0 0 30%;
                        text-align: right;
                    }
                }
            }

            .tablle-info {
                padding: 20px;
                margin-bottom: 0;

                div {
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: #fff;
                    margin-bottom: 0;

                    &:nth-child(2) {
                        flex: 1;
                    }

                    &:last-child {
                        flex: 0 0 30%;
                        text-align: right;
                    }
                }
            }
        }

        .montitle {
            padding: 20px;
            background: #232323;
            margin-bottom: 0;

            div {
                flex: 0 0 50%;
                margin-bottom: 0;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.6);

                &:last-child {
                    text-align: right;
                }
            }
        }

        .montime {
            padding: 20px;

            div {
                flex: 0 0 50%;
                margin-bottom: 0;
                font-size: 13px;
                color: #fff;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .notice-time {
        padding: 23px 40px 0;
        font-size: 16px;
        text-align: right;
        color: rgba(255, 255, 255, 0.6);
    }

    .notice-btn {
        margin: 23px auto 0;
        width: 418px;
        height: 52px;
        background: url("../../public/images/new/btnbg25.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        font-size: 18px;
        color: #000000;
        text-align: center;
        line-height: 52px;
    }
}

@media (max-width:1200px) {
    .reinvest {
        padding: 68px 0 70px;

        .reinvest-top {
            padding: 0 16px;
            background-size: 100% cover;

            .top-con {
                width: 100%;

                .top-title {
                    padding: 40px 0 20px;
                    font-size: 18px;
                    line-height: normal;
                }

                .top-name {
                    font-size: 30px;
                    line-height: normal;
                }

                .top-wrappar {
                    flex-wrap: wrap;

                    .wrappar-info {
                        flex: 0 0 100%;

                        .info-list {
                            .list-award {
                                .award-count {
                                    .count {
                                        div {
                                            font-size: 16px;
                                            line-height: normal;
                                        }

                                        p {
                                            font-size: 24px;
                                            line-height: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .wrappar-rules {
                        flex: 0 0 100%;
                        height: max-content !important;
                        overflow-y: inherit;
                    }
                }

                .top-market {
                    margin-top: 30px;
                }
            }
        }

        .reinvest-con {
            width: 100%;
            padding: 0 16px;
            margin-top: 30px;

            .reinvest-title {
                div {
                    font-size: 24px;
                }
            }

            .reinvest-rules {
                margin: 25px 0 20px;
            }
        }
    }

    .modal-con {
        width: calc(100vw - 8px);
        padding: 16px;
    }
}

@media (max-width:767px) {
    .reinvest {
        padding: 56px 0;

        .reinvest-top {
            padding: 0;
            background: none;

            .top-con {
                padding: 18px 16px 0;
                background: url('../../public/images/reward/reinvestbg1.png') top no-repeat;
                background-size: 100% 294px;

                .top-btn {
                    .flex {
                        flex: 0 0 32px;
                        height: 32px;
                        padding: 0;
                        margin-left: 8px;

                        div {
                            display: none;
                        }

                        img {
                            margin: 6px 0 0 0;
                        }

                        &:first-child {
                            img {
                                width: 18px;
                                height: 18px;
                                margin: 8px 0 0;
                            }
                        }
                    }
                }

                .top-title {
                    padding: 20px 0;
                    font-size: 16px;
                }

                .top-name {
                    font-size: 32px;
                    text-align: center;
                }

                .top-time {
                    width: 100%;
                    margin: 16px auto 0;
                    font-size: 14px;
                    text-align: left;
                    flex-wrap: wrap;

                    div {
                        flex: 0 0 100%;
                    }
                }

                .top-wrappar {
                    margin-top: 37px;

                    .wrappar-info {
                        .info-list {
                            .info-record {
                                bottom: 23px;
                                width: calc(100% - 8px);
                                height: 40px;
                                right: auto;
                                left: 16px;
                                background: url('../../public/images/reward/btnbg11.png') center no-repeat;
                                background-size: 100% 100%;
                                color: #FFFFFF;
                                font-size: 14px;
                                font-weight: 600;
                                text-indent: 0;
                            }

                            .list-title {

                                flex-wrap: wrap;

                                .title {
                                    flex: 0 0 100%;
                                    font-size: 16px;
                                }

                                .flex {
                                    margin-top: 6px;
                                    flex: 0 0 100%;
                                    font-size: 13px;
                                    line-height: 20px;

                                    .block,
                                    .colon {
                                        font-size: 13px;
                                        line-height: 20px;
                                    }
                                }
                            }

                            .list-award {
                                padding: 17px 20px 60px;
                                border-radius: 10px;
                                background: #181818;

                                .award-count {
                                    padding: 0;
                                    height: auto;
                                    flex-wrap: wrap;

                                    .count {
                                        flex: 0 0 50%;

                                        &:last-child {
                                            margin: 20px 0 18px;
                                        }

                                        div {
                                            font-size: 12px;
                                            line-height: 12px;

                                            img {
                                                width: 12px;
                                                height: 12px;
                                                margin: 0 0 0 2px;
                                            }

                                            &::after {
                                                width: 12px;
                                                height: 12px;
                                                top: 0;
                                            }
                                        }

                                        p {
                                            margin-top: 11px;
                                            font-size: 18px;
                                            color: #fff;
                                        }
                                    }
                                }

                                .award-time {
                                    width: 100%;
                                    padding: 14px 0 12px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    .wrappar-rules {
                        margin-top: 30px;
                        padding: 20px 16px;

                        .title {
                            margin-bottom: 12px;
                            font-size: 18px;
                            line-height: normal;
                        }

                        .desc {
                            font-size: 13px;
                        }

                        .rules-table {
                            padding: 14px;
                        }
                    }
                }

                .top-market {
                    display: none;
                }
            }
        }

        .reinvest-con {
            width: calc(100vw - 8px);
            padding: 0;

            .reinvest-tab {
                display: none;
            }

            .h5tab {
                display: flex;
            }

            .reinvest-title {
                div {
                    font-size: 20px;
                    line-height: normal;
                }

                img {
                    flex: 0 0 75px;
                    width: 70px;
                    height: 13px;
                    margin-top: 7px;
                }
            }

            .reinvest-rules {
                margin-top: 24px;
                padding: 0;
                border: 0;

                div {
                    font-size: 13px;
                }
            }

            .table-thead {
                padding: 14px;

                div {
                    font-size: 13px;

                    &:first-child {
                        flex: 0 0 50px;
                    }

                    &:last-child {
                        flex: 0 0 80px;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }
                }
            }

            .tbody-info {
                padding: 14px;

                div {
                    font-size: 13px;

                    &:first-child {
                        flex: 0 0 50px;
                    }

                    &:last-child {
                        flex: 0 0 80px;
                    }

                    &:nth-child(2) {
                        text-align: center;
                        color: #fff;
                    }
                }
            }
        }
    }
}</style>